// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cooking-classes-js": () => import("./../src/pages/cooking-classes.js" /* webpackChunkName: "component---src-pages-cooking-classes-js" */),
  "component---src-pages-corporate-catering-js": () => import("./../src/pages/corporate-catering.js" /* webpackChunkName: "component---src-pages-corporate-catering-js" */),
  "component---src-pages-events-catering-js": () => import("./../src/pages/events-catering.js" /* webpackChunkName: "component---src-pages-events-catering-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-party-js": () => import("./../src/pages/kids-party.js" /* webpackChunkName: "component---src-pages-kids-party-js" */),
  "component---src-pages-menu-js": () => import("./../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */)
}

